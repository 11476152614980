import React from "react"
import AfS from "../../../assets/images/afs_logotyp_standard.svg"
import { Link } from "gatsby"
import useMainSiteUrl from "../mainSiteUrl"
const Header = () => {
  const mainSiteUrl = useMainSiteUrl()

  return (
    <header className="tag-wrap">
      <div className="box flex flex-row justify-center bg-white">
        <div style={{ height: "calc(60px + 4vw)", width: "100%" }}>
          <div className="flex flex-col-reverse md:flex-row h-min items-center md:items-start md:justify-around my-auto">
            <div className="flex-1"></div>
            <div className="h-full">
              <div
                style={{
                  height: "calc(60px + 2vw)",
                  width: "calc(100px + 5vw)",
                }}
                className="flex flex-col h-full justify-items-center justify-start mt-2 md:mt-0 md:justify-around md:justify-center"
              >
                <Link to="/">
                  <AfS className="h-5/6 mx-auto my-auto" />
                </Link>
              </div>
            </div>
            <div className="flex flex-1 justify-center md:justify-end items-center self-stretch">
              <div className="text-gray-400 md:text-gray-600 md:px-12 text-xs">
                <a href={mainSiteUrl} className="no-underline">
                  Gå till partiets vanliga sida här! &#8617;
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
