import { useStaticQuery, graphql } from "gatsby"

const useMainSiteUrl = () => {
  const {
    site: {
      siteMetadata: { mainSiteUrl },
    },
  } = useStaticQuery(graphql`
    query mainSiteUrlQuery {
      site {
        siteMetadata {
          mainSiteUrl
        }
      }
    }
  `)
  return mainSiteUrl
}
export default useMainSiteUrl
