import React from "react"
import BackgroundImage from "gatsby-background-image-es5"
import { Container } from "."
import { graphql, useStaticQuery } from "gatsby"

const classes = "slanted min-h-1/2 "

const Slanted = ({
  imageData = null,
  style,
  children,
  innerClass = "",
  className,
  useStandardImage = false,
}) => {
  const {
    fileName: {
      childImageSharp: { fluid: standardImageData },
    },
  } = useStaticQuery(graphql`
    query StandardImageQuery {
      fileName: file(relativePath: { eq: "kyrka.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  if (imageData) {
    return (
      <BackgroundImage
        Tag="div"
        fluid={imageData}
        className={classes + innerClass}
        style={style}
      >
        <Container>{children}</Container>
      </BackgroundImage>
    )
  }
  if (useStandardImage) {
    return (
      <BackgroundImage
        Tag="div"
        fluid={standardImageData}
        className={classes + innerClass}
        style={style}
      >
        <Container>{children}</Container>
      </BackgroundImage>
    )
  }
  return (
    <div className={classes + innerClass} style={style}>
      <Container>{children}</Container>
    </div>
  )
}

export const NormalHeading = ({ children, white = false, align = null }) => (
  <h1
    className={`text-3xl sm:text-6xl  ${white && "text-white text-shadow-lg"} ${
      align && "text-" + align
    }`}
  >
    {children}
  </h1>
)

export const HeroHeading = ({ children }) => (
  <h1 className="text-white text-4xl sm:text-8xl lg:text-9xl text-shadow-lg text-right">
    {children}
  </h1>
)

export default Slanted
