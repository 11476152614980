import React from "react"
import Footer from "./footer"
import Header from "./header"

const Layout = ({ children }) => {
  const title = "Title"
  const image = null
  return (
    <>
      <Header></Header>
      <div style={{ marginTop: "50px", zIndex: "2", position: "relative" }}>
        {children}
      </div>
      <Footer style={{ zIndex: "1" }}></Footer>
    </>
  )
}

export const Container = ({ children, className }) => (
  <div
    className={
      "flex flex-col py-8 px-4 w-full  lg:max-w-screen-md  h-full mx-auto " +
      className
    }
  >
    {children}
  </div>
)

export default Layout
