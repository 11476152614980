import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { htmlToText } from "html-to-text"
import truncate from "truncate"

const SEO = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const {
    wp: { settings: wpSettings },
    fileName: {
      childImageSharp: {
        fixed: { src: defaultImage },
      },
    },
    site: {
      siteMetadata: { url: siteUrl },
    },
  } = useStaticQuery(query)
  const { defaultTitle, defaultDescription } = wpSettings

  const seo = {
    title: title || defaultTitle,
    description: truncate(htmlToText(description), 160) || defaultDescription,
    image: siteUrl + (image ? image.src : defaultImage),
    url: `${siteUrl}${pathname}`,
  }
  const titleTemplate = `%s | Alternativ för Sverige`
  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )
}
export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  article: PropTypes.bool,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}
const query = graphql`
  query seoQuery {
    wp {
      settings: allSettings {
        defaultDescription: generalSettingsDescription
        defaultTitle: generalSettingsTitle
      }
    }
    fileName: file(relativePath: { eq: "kyrka.jpg" }) {
      childImageSharp {
        fixed(quality: 90, width: 1200) {
          src
        }
      }
    }
    site {
      siteMetadata {
        url
      }
    }
  }
`
